var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loader',{model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),(!_vm.production)?_c('div',[_c('v-btn',{attrs:{"elevation":"1","color":"success"},on:{"click":_vm.generateWithFunctions}},[_vm._v(_vm._s(_vm.$t('generate')))])],1):_vm._e(),_c('section',{attrs:{"id":"customDatasheet"}},[_c('section',[_c('table',{style:({
          width: '100%'
        })},[_c('tbody',[_c('tr',[_c('td',{style:({
                width: '300px'
              })},[_c('img',{staticClass:"logo",attrs:{"width":"300","src":"https://mp-ypa-general.s3.ap-southeast-1.amazonaws.com/sps/preference/674/LOGO_20210120050157653949.jpg"}})]),_c('td',{style:({
                textAlign: 'right',
              })},[_c('h3',{staticStyle:{"margin":"0px","padding":"0px"}},[_vm._v(_vm._s(_vm.user.office.name))]),_c('p',{staticStyle:{"margin":"0px","padding":"0px"}},[_vm._v(_vm._s(_vm.user.office.address)),_c('br'),_vm._v(" "+_vm._s(_vm.user.office.country)),_c('br'),_vm._v(" Tel: "+_vm._s(_vm.user.office.tel))])])])])])]),_c('section',[_c('table',{style:({
          width: '100%',
          marginTop: '25px'
        })},[_c('tbody',[_c('tr',[_c('td',[_c('div',{staticStyle:{"margin":"0px","padding":"0px"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('customDatasheet.created_by'))+":")]),_vm._v(" "+_vm._s(_vm.$store.getters.user.name)+" ")]),_c('p',{staticStyle:{"margin":"0px","padding":"0px"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('customDatasheet.email'))+":")]),_vm._v(" "+_vm._s(_vm.$store.getters.user.email)+" ")]),(_vm.$store.getters.user.tel !== null)?_c('p',{staticStyle:{"margin":"0px","padding":"0px"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('customDatasheet.phone'))+":")]),_vm._v(" "+_vm._s(_vm.$store.getters.user.tel_country_code)+" "+_vm._s(_vm.$store.getters.user.tel)+" ")]):_vm._e()]),_c('td',{style:({
                textAlign: 'right'
              })},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t('orders.date'))+":")]),_vm._v(" "+_vm._s(_vm.formatDate(new Date())))]),(_vm.productParams.incoterm !== 'REVOOLOOP')?_c('div',[_c('strong',[_vm._v("Incoterm:")]),_vm._v(" "+_vm._s(_vm.productParams.incoterm))]):_vm._e()])])])])]),_c('section',[_c('h1',{staticClass:"hide-hide",style:({
          fontSize: '42px',
          textAlign: 'center',
          color: '#B71C1C',
          fontWeight: 700,
          padding: 0,
          marginTop: '25px',
          paddingBottom: '0px',
          marginBottom: '0px'
        })},[_vm._v(_vm._s(_vm.p.title))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.p.title),expression:"p.title"}],staticClass:"hide",style:({
          fontSize: '42px',
          textAlign: 'center',
          color: '#B71C1C',
          fontWeight: 700,
          padding: 0,
          marginTop: '25px',
          paddingBottom: '0px',
          marginBottom: '0px'
        }),attrs:{"type":"text"},domProps:{"value":(_vm.p.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.p, "title", $event.target.value)}}}),_c('h3',{staticClass:"hide-hide",style:({
          fontSize: '16px',
          textAlign: 'center',
          color: '#666',
          fontWeight: 700,
          marginBottom: '15px',
          marginTop: '0px',
          padding: 0,
        })},[_vm._v(_vm._s(_vm.p.subtitle))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.p.subtitle),expression:"p.subtitle"}],staticClass:"hide",style:({
          fontSize: '16px',
          textAlign: 'center',
          color: '#666',
          fontWeight: 700,
          marginBottom: '15px',
          marginTop: '0px',
          padding: 0,
        }),attrs:{"type":"text"},domProps:{"value":(_vm.p.subtitle)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.p, "subtitle", $event.target.value)}}})]),_c('section',[_c('table',[_c('tr',[(_vm.productImage !== null)?_c('td',[_c('img',{attrs:{"width":"300","src":_vm.productImage}})]):_vm._e(),_c('td',[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.product.name))]),_c('p',[_vm._v(_vm._s(_vm.product.description))]),_c('div',{staticClass:"product_details"},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t('ref'))+":")]),_vm._v(" "+_vm._s(_vm.product.status))]),_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t('origin'))+":")]),_vm._v(" "+_vm._s(_vm.product.id))]),_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t('status'))+":")]),_vm._v(" "+_vm._s(_vm.product.origin))]),_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t('production_time'))+":")]),_vm._v(" "+_vm._s(_vm.product.production_time))]),_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t('hs_code'))+":")]),_vm._v(" "+_vm._s(_vm.product.hs_code))])])])])])]),_c('section',[_c('h2',{staticStyle:{"color":"#B71C1C"}},[_vm._v(_vm._s(_vm.$t('options')))])]),_vm._l((_vm.product.options),function(option){return _c('section',{key:option.id,staticClass:"pdf-item no-break"},[_c('table',{style:({
          width: '100%'
        })},[_c('tr',{class:[ { hide: !option.show } ]},[_c('td',{staticStyle:{"padding":"5px","border-bottom":"1px solid #CCC"}},[_c('ul',{style:({
                padding: '0px',
                margin: '0px'
              })},[_c('div',{staticClass:"hide"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(option.show),expression:"option.show"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(option.show)?_vm._i(option.show,null)>-1:(option.show)},on:{"change":function($event){var $$a=option.show,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(option, "show", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(option, "show", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(option, "show", $$c)}}}}),_vm._v("Show option")]),_c('strong',{staticStyle:{"color":"#B71C1C"}},[_vm._v(_vm._s(option.group_name)+":")]),_vm._v(" "+_vm._s(option.group_value)+" ("+_vm._s(option.ref)+") "),_c('li',[_c('strong',[_vm._v(_vm._s(_vm.$t('components.products.carton_size'))+":")]),_vm._v(" "+_vm._s(_vm.mxMeas(option)))]),_c('li',[_c('strong',[_vm._v(_vm._s(_vm.$t('moq'))+":")]),_vm._v(" "+_vm._s(option.min_order)+" "+_vm._s(_vm.$tc('components.products.carton', 1)))]),_c('li',[_c('strong',[_vm._v(_vm._s(_vm.$t('components.products.weight'))+":")]),_vm._v(" "+_vm._s(option.weight_per_carton))])]),_c('ul',{style:({
                padding: '0px',
                margin: '0px'
              }),domProps:{"innerHTML":_vm._s(_vm.mxPacking(option))}})]),_c('td',{staticStyle:{"padding":"5px","border-bottom":"1px solid #CCC"}},[(_vm.productParams.incoterm === 'REVOOLOOP')?_c('ul',{staticClass:"price-tiers"},[_vm._l((option.tiers),function(t,index){return [_c('li',{key:index},[(t.to === 'onwards')?_c('div',[_vm._v(_vm._s(_vm.$t('notifications.from'))+" "+_vm._s(t.from)+" "+_vm._s(_vm.$t('components.products.cartons_onwards')))]):_c('div',[_vm._v(_vm._s(t.from)+" to "+_vm._s(t.to)+" "+_vm._s(_vm.$tc('carton',2)))]),_c('p',[_c('strong',{staticClass:"hide-hide"},[_vm._v(_vm._s(t.cost_per_carton))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(t.cost_per_carton),expression:"t.cost_per_carton"}],staticClass:"hide",style:({
                        fontSize: '14px',
                        textAlign: 'center',
                        color: '#666',
                        fontWeight: 700,
                        width: '100px',
                        textAlign: 'left'
                      }),attrs:{"type":"text"},domProps:{"value":(t.cost_per_carton)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(t, "cost_per_carton", $event.target.value)}}}),_vm._v(" / "+_vm._s(_vm.$tc('components.products.carton',1))+" "),_c('br'),_c('span',{staticClass:"hide-hide"},[_vm._v(_vm._s(t.cost_per_unit))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(t.cost_per_unit),expression:"t.cost_per_unit"}],staticClass:"hide",style:({
                        fontSize: '14px',
                        textAlign: 'center',
                        color: '#666',
                        fontWeight: 700,
                        width: '100px',
                        textAlign: 'left'
                      }),attrs:{"type":"text"},domProps:{"value":(t.cost_per_unit)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(t, "cost_per_unit", $event.target.value)}}}),_vm._v(" / "+_vm._s(_vm.$tc('components.products.unit',1))+" ")])])]})],2):_vm._e(),(_vm.productParams.incoterm === 'DDP')?_c('ul',{staticClass:"price-tiers"},[_c('li',[_c('p',[_c('strong',{staticClass:"hide-hide"},[_vm._v(_vm._s(option.ddp_carton_sale_price_string))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(option.ddp_carton_sale_price_string),expression:"option.ddp_carton_sale_price_string"}],staticClass:"hide",style:({
                      fontSize: '14px',
                      textAlign: 'center',
                      color: '#666',
                      fontWeight: 700,
                      width: '100px',
                      textAlign: 'left'
                    }),attrs:{"type":"text"},domProps:{"value":(option.ddp_carton_sale_price_string)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(option, "ddp_carton_sale_price_string", $event.target.value)}}}),_vm._v(" / "+_vm._s(_vm.$tc('components.products.carton',1))+" "),_c('br'),_c('span',{staticClass:"hide-hide"},[_vm._v(_vm._s(option.ddp_unit_sale_price_string))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(option.ddp_unit_sale_price_string),expression:"option.ddp_unit_sale_price_string"}],staticClass:"hide",style:({
                      fontSize: '14px',
                      textAlign: 'center',
                      color: '#666',
                      fontWeight: 700,
                      width: '100px',
                      textAlign: 'left'
                    }),attrs:{"type":"text"},domProps:{"value":(option.ddp_unit_sale_price_string)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(option, "ddp_unit_sale_price_string", $event.target.value)}}}),_vm._v(" / "+_vm._s(option.packing_base_unit_description === '' ? option.inner_unit_text : option.packing_base_unit_description)+" ")])])]):_vm._e(),(_vm.productParams.incoterm === 'FOB')?_c('ul',{staticClass:"price-tiers"},[_c('li',[_c('p',[_c('strong',{staticClass:"hide-hide"},[_vm._v(_vm._s(option.carton_price_string))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(option.carton_price_string),expression:"option.carton_price_string"}],staticClass:"hide",style:({
                      fontSize: '14px',
                      textAlign: 'center',
                      color: '#666',
                      fontWeight: 700,
                      width: '100px',
                      textAlign: 'left'
                    }),attrs:{"type":"text"},domProps:{"value":(option.carton_price_string)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(option, "carton_price_string", $event.target.value)}}}),_vm._v(" / "+_vm._s(_vm.$tc('components.products.carton',1))+" "),_c('br'),_c('span',{staticClass:"hide-hide"},[_vm._v(_vm._s(option.unit_price_string))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(option.unit_price_string),expression:"option.unit_price_string"}],staticClass:"hide",style:({
                      fontSize: '14px',
                      textAlign: 'center',
                      color: '#666',
                      fontWeight: 700,
                      width: '100px',
                      textAlign: 'left'
                    }),attrs:{"type":"text"},domProps:{"value":(option.unit_price_string)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(option, "unit_price_string", $event.target.value)}}}),_vm._v(" / "+_vm._s(option.packing_base_unit_description === '' ? option.inner_unit_text : option.packing_base_unit_description)+" ")])])]):_vm._e()])])])])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }